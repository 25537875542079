import microbiome from '../../images/microbiome.jpg';
import sleep from '../../images/sleep.jpg';
import sleep2 from '../../images/sleep2.jpg';
import transference from '../../images/transference.jpg';
import handsin from '../../images/handsin.jpg';
import specialtyGroups from '../../images/specialtyGroups.jpg';
import plantFlipped from '../../images/plantFlipped.jpg';
import handsDarkened from '../../images/handsDarkened.png';
import faithImage3Small from '../../images/faithImage3Small.jpg';
import ppnTherapist5 from '../../images/ppnTherapist5.jpg';
import ppnTherapist1small from '../../images/ppnTherapist1Small.jpg';
import ppnTherapist2small from '../../images/ppnTherapist2Small.jpg';
import ppnTherapist3small from '../../images/ppnTherapist3Small.jpg';
import ppnTherapist4 from '../../images/ppnTherapist4.jpg';
import ppnTherapist6 from '../../images/ppnTherapist6.jpg';
import resourceImg from '../../images/resourceImg.jpg';
import sherriff from '../../images/sheriff.jpg';
import peacefulgirl from '../../images/peacfulgirl.jpg';
import soccer from '../../images/soccer.jpg';
import hope from '../../images/hope.jpg';
import officerAndNeighbor from '../../images/officerAndNeighbor.jpg';
import dietandmentalhealth2 from '../../images/dietandmentalhealth2.jpg';
import mindfullnessJourney from '../../images/mindfullnessJourney.jpg';
import sauna from '../../images/sauna.jpg';
import polar from '../../images/polar.jpg';
import fireHands from '../../images/fireHands.jpg';
import social from '../../images/social.png';
import transition from '../../images/transition.png';
import sad from '../../images/sad.jpg';
import thanksgiving from '../../images/thanksgiving.png'
import gratitude from '../../images/gratitude.jpg'
import hustleAndBustle from '../../images/hustleAndBustle.jpg'
import wonderful from '../../images/wonderful.jpg'
import winter from '../../images/winter.jpg'
import dec22 from '../../images/dec22.png'
import fine from '../../images/fine.png'
import mythsfacts from '../../images/mythsfacts.jpg'
import selfcare from '../../images/selfcare.jpg'
import selflove from '../../images/selflove.png'
import healthyRelationships from '../../images/healthyRelationships.png'
import worklifebal from '../../images/worklifebal.jpg';
import brainrun from '../../images/brainrun.jpg';
import brainWeights from '../../images/brainWeights.png';
import Clock from '../../images/Clock.png';
import ChallengeAccepted from '../../images/ChallengeAccepted.jpg';
import fitnessplan from '../../images/fitnessplan.jpg';
import healthFitness from '../../images/healthFitness.jpg';

export const blogdata = [
  {
    "id": 51,
    "title": "One Year Fitness Plan Continued",
    "link": "#/fitnessplan2",
    "description": " In last week’s Mental Health Minute, the focus was physical fitness, building your strength and endurance, and how to maintain your one-year plan. This week continues with September through December.",
    "img": healthFitness,
    "topic": "fitness plan",
    "alpha": "F",
    "author": "Bobbi Mcgraw",
    "date": "March 29, 2024"
  },
  {
    "id": 50,
    "title": "One Year Fitness Plan",
    "link": "#/fitnessplan",
    "description": " We’re already heading toward the end of March, but it’s not too late to plan your one-year fitness routine.",
    "img": fitnessplan,
    "topic": "fitness plan",
    "alpha": "F",
    "author": "Bobbi Mcgraw",
    "date": "March 22, 2024"
  },
  {
    "id": 49,
    "title": "A Physical Fitness Challenge",
    "link": "#/physfitchallenge",
    "description": "As each person engages in a fitness challenge and meets their own goals, they build autonomy and start choosing healthy habits.",
    "img": ChallengeAccepted,
    "topic": "physical fitness challenge",
    "alpha": "P",
    "author": "Bobbi Mcgraw",
    "date": "March 15, 2024"
  },
  {
    "id": 48,
    "title": "Spring Ahead",
    "link": "#/springahead",
    "description": "These are just a few steps to help adjust to the time change, make your life a bit easier, and prepare to take care of yourself.",
    "img": Clock,
    "topic": "spring ahead time change",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "March 8, 2024"
  },
  {
    "id": 47,
    "title": "The Science of Mental and Physical Health",
    "link": "#/mentalandphysical",
    "description": "Things like cortisol, adrenal glands, the amygdala, stress…among other things… and how these all combine to not only help you physically, but mentally and emotionally. ",
    "img": brainWeights,
    "topic": "physical fitness health",
    "alpha": "P",
    "author": "Bobbi Mcgraw",
    "date": "March 1, 2024"
  },
  {
    "id": 46,
    "title": "Physical Fitness",
    "link": "#/physicalfitness",
    "description": "For the month of March, the MHM will focus on the correlation between physical and mental health with a few tips on how to maintain and improve both. ",
    "img": brainrun,
    "topic": "physical fitness health",
    "alpha": "P",
    "author": "Bobbi Mcgraw",
    "date": "February 23, 2024"
  },
  {
    "id": 45,
    "title": "You'd Rather Be At Work",
    "link": "#/ratherbeatwork",
    "description": "     Most first responders will experience a period of time during their career when life outside of work seems hard. This is normal. This is OK — until it’s not.",
    "img": worklifebal,
    "topic": "home life work",
    "alpha": "W",
    "author": "Bobbi Mcgraw",
    "date": "February 16, 2024"
  },
  {
    "id": 44,
    "title": "Healthy Relationships",
    "link": "#/healthyrelationships",
    "description": "A healthy relationship just feels different, it feels good to be around, it is contagious, and leaves people with a sense of human goodness. ",
    "img": healthyRelationships,
    "topic": "healthy relationships",
    "alpha": "H",
    "author": "Bobbi Mcgraw",
    "date": "February 9, 2024"
  },
  {
    "id": 43,
    "title": "Self Love",
    "link": "#/selflove",
    "description": "Your relationship with yourself is the most important one you will ever have. To truly love and understand others, you need to start with yourself",
    "img": selflove,
    "topic": "self love",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "February 2, 2024"
  },
  {
    "id": 42,
    "title": "Questions",
    "link": "#/questions",
    "description": "Questions about mental health, how does age, career, and our perception of it affect us?",
    "img": ppnTherapist5,
    "topic": "questions",
    "alpha": "Q",
    "author": "Bobbi Mcgraw",
    "date": "Janurary 26, 2024"
  },
  {
    "id": 41,
    "title": "Self Care Isn't Selfish",
    "link": "#/selfcare",
    "description": "Self-care is at the heart of preserving our whole health. However, practicing self-care isn’t as easy as it sounds. We say we are taking care of ourselves, but are we actually doing it?",
    "img": selfcare,
    "topic": "self care",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "Janurary 19, 2024"
  },
  {
    "id": 40,
    "title": "Myths and Facts",
    "link": "#/mythsandfacts",
    "description": "Myths and misconceptions about mental health influence society’s beliefs and attitudes about suicide, which could be a major barrier to seeking help for themselves and their loved ones. ",
    "img": mythsfacts,
    "topic": "myths misconceptions beliefs attitudes",
    "alpha": "M",
    "author": "Bobbi Mcgraw",
    "date": "Janurary 12, 2024"
  },
  {
    "id": 39,
    "title": "What Mental Health Is",
    "link": "#/whatmentalhealthis",
    "description": "Literally everything we do and experience in life either involves or affects our mental health to some extent. So why not take care of it, be proud of it, and maintain it the way we do our physical health? ",
    "img": ppnTherapist4,
    "topic": "mental health",
    "alpha": "G",
    "author": "Bobbi Mcgraw",
    "date": "January 5, 2024"
  },
  {
    "id": 38,
    "title": "Goodbye to the old, hello to the new.",
    "link": "#/fine",
    "description": "In 2024 be ready for Mental Health Minutes that will inspire you, and give you hope. ",
    "img": fine,
    "topic": "resilience hope new year wishes",
    "alpha": "G",
    "author": "Bobbi Mcgraw",
    "date": "December 29, 2023"
  },
  {
    "id": 37,
    "title": "Happy Holidays",
    "link": "#/happyholidays",
    "description": "Happy holidays to you, to your family, and all those you hold dear. ",
    "img": dec22,
    "topic": "Holidays first responder thank you",
    "alpha": "H",
    "author": "Bobbi Mcgraw",
    "date": "December 22, 2023"
  },
  {
    "id": 36,
    "title": "If I Could Make You Happy",
    "link": "#/makehappy",
    "description": "A poem from our own Bobbi McGraw",
    "img": winter,
    "topic": "Happy poetry service courage hero",
    "alpha": "H",
    "author": "Bobbi Mcgraw",
    "date": "December 15, 2023"
  },
  {
    "id": 35,
    "title": "The Most Wonderful Time of the Year",
    "link": "#/wonderful",
    "description": "We smile more, say hi to strangers, and find ourselves in a whole other frame of mind. Music does that.",
    "img": wonderful,
    "topic": "wonderful time of the year",
    "alpha": "W",
    "author": "Bobbi Mcgraw",
    "date": "December 8, 2023"
  },
  {
    "id": 34,
    "title": "Hustle and Bustle",
    "link": "#/hustle",
    "description": "What an exhilarating time of year! The music, lights, the excitement of children, the food, family get-togethers, and the love surrounding us during one of the happiest of holidays.",
    "img": hustleAndBustle,
    "topic": "hustle",
    "alpha": "H",
    "author": "Bobbi Mcgraw",
    "date": "December 1, 2023"
  },
  {
    "id": 33,
    "title": "Gratitude",
    "link": "#/gratitude",
    "description": "If we really want to be happy and content, gratitude and integrity can do a lot for us, a lot for our family, friends, career, and society.",
    "img": gratitude,
    "topic": "gratitude",
    "alpha": "G",
    "author": "Bobbi Mcgraw",
    "date": "November 24, 2023"
  },
  {
    "id": 32,
    "title": "Thanksgiving",
    "link": "#/thanks",
    "description": "To all of our first responders and your families, we wish you the most peaceful, content Thanksgiving. ",
    "img": thanksgiving,
    "topic": "thanks",
    "alpha": "T",
    "author": "Bobbi Mcgraw",
    "date": "November 17, 2023"
  },
  {
    "id": 31,
    "title": "Seasonal Affective Disorder",
    "link": "#/sad",
    "description": "What are your thoughts about the winter blues? What do you know about Seasonal Affective Disorder, or Seasonal Depression?",
    "img": sad,
    "topic": "seasonal affective disorder",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "November 10, 2023"
  },
  {
    "id": 30,
    "title": "Transition",
    "link": "#/transition",
    "description": "As we transition from spooky season into the season of gratitude and finding our blessings, take a moment to recognize the good around you.",
    "img": transition,
    "topic": "gratitude",
    "alpha": "G",
    "author": "Bobbi Mcgraw",
    "date": "November 3, 2023"
  },
  {
    "id": 29,
    "title": "Social Relationships",
    "link": "#/socialrelationships",
    "description": "Connecting with others can be rewarding and bring you a great deal of happiness and relief.",
    "img": social,
    "topic": "friends family social relationships",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "October 27, 2023"
  },
  {
    "id": 28,
    "title": "Anxiety or Panic",
    "link": "#/anxietyorpanic",
    "description": "Ever wonder what the difference is between a panic attack and an anxiety attack? Have you experienced either of these?",
    "img": fireHands,
    "topic": "anxiety panic attack ",
    "alpha": "A",
    "author": "Bobbi Mcgraw",
    "date": "October 20, 2023"
  },
    {
    "id": 27,
    "title": "Polar Plunge",
    "link": "#/polarplunge",
    "description": "Cryotherapy, or ice bath, is used to flush toxins and enrich red blood cells with oxygen, nutrients and enzymes.",
    "img": polar,
    "topic": "cryotherapy polar plunge cold therapy",
    "alpha": "P",
    "author": "Bobbi Mcgraw",
    "date": "October 13, 2023"
  },

  {
    "id": 26,
    "title": "Heat Therapy",
    "link": "#/heattherapy",
    "description": "Heat therapy can help decompress your mind, relax your muscles, and increase resilience to uncontrolled stressors.",
    "img": sauna,
    "topic": "heat thermo therapy",
    "alpha": "H",
    "author": "Bobbi Mcgraw",
    "date": "October 6, 2023"
  },
  {
    "id": 25,
    "title": "A Mindfullness Journey",
    "link": "#/mindfullness",
    "description": "The primitive human capacity to be completely present, thoughtfully bringing your attention to the present moment.",
    "img": mindfullnessJourney,
    "topic": "mindfullness awareness present self-awareness",
    "alpha": "M",
    "author": "Bobbi Mcgraw",
    "date": "September 29, 2023"
  },
  {
    "id": 24,
    "title": "The Cost of Caring",
    "link": "#/costofcaring",
    "description": "The cost of caring, also referred to as compassion fatigue, is real, it is normal for those in high-stress positions. ",
    "img": ppnTherapist5,
    "topic": "diet affect affects mental health breakfast lunch dinner healthy food",
    "alpha": "D",
    "author": "Bobbi Mcgraw",
    "date": "September 22, 2023"
  },
  {
    "id": 23,
    "title": "Diet and Mental Health",
    "link": "#/diet",
    "description": "You know your diet is important, but do you realize how it affects your brain and your mental health? ",
    "img": dietandmentalhealth2,
    "topic": "diet affect affects mental health breakfast lunch dinner healthy food",
    "alpha": "D",
    "author": "Bobbi Mcgraw",
    "date": "September 15, 2023"
  },
  {
    "id": 22,
    "title": "Worst Day",
    "link": "#/worstday",
    "description": "Today was the absolute worst day ever ...or was it?",
    "img": hope,
    "topic": "fun read change in thinking perspective point of view",
    "alpha": "W",
    "author": "Bobbi Mcgraw",
    "date": "September 8, 2023"
  },
  {
    "id": 21,
    "title": "Support for Families",
    "link": "#/supportforfamilies",
    "description": "Families of first responders understand that nearly every aspect of their life is affected by their loved one’s career.",
    "img": officerAndNeighbor,
    "topic": "family support familial families",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "September 1, 2023"
  },
  {
    "id": 20,
    "title": "The Power of Play",
    "link": "#/powerofplay",
    "description": "Play is Never Over, We Just Press Pause. How Will You Play Today?",
    "img": soccer,
    "topic": "Physical Health play",
    "alpha": "P",
    "author": "Bobbi Mcgraw",
    "date": "August 25, 2023"
  },
  {
    "id": 19,
    "title": "Financial Freedom",
    "link": "#/financialfreedom",
    "description": "Is it possible to be debt-free and financially independent?",
    "img": peacefulgirl,
    "topic": "Finances financial freedom",
    "alpha": "F",
    "author": "Bobbi Mcgraw",
    "date": "August 18, 2023"
  },
  {
    "id": 18,
    "title": "Culture of Wellness",
    "link": "#/culture",
    "description": "Focusing on creating a culture of wellness in the first responder world.",
    "img": sherriff,
    "topic": "Wellness",
    "alpha": "W",
    "author": "Bobbi Mcgraw",
    "date": "August 11, 2023"
  },
  {
    "id": 17,
    "title": "Breathing",
    "link": "#/breathing",
    "description": "Did you know there is science that backs the positive effects of controlled breathing?",
    "img": handsin,
    "topic": "Breathing",
    "alpha": "B",
    "author": "Bobbi Mcgraw",
    "date": "August 4, 2023"
  },
  {
    "id": 16,
    "title": "Music",
    "link": "#/music",
    "description": "Listen to your favorite music to clear your mind and hit the reset button.",
    "img": specialtyGroups,
    "topic": "Music",
    "alpha": "M",
    "author": "Bobbi Mcgraw",
    "date": "July 28, 2023"
  },
  {
    "id": 15,
    "title": "Mental Health in a Positive Light",
    "link": "#/positivelight",
    "description": "Adjusting our own thinking, and our interactions surrouding mental health.",
    "img": ppnTherapist6,
    "topic": "Positivity",
    "alpha": "P",
    "author": "Bobbi Mcgraw",
    "date": "July 21, 2023"
  },
  {
    "id": 14,
    "title": "Vicarious Trauma, Part 2",
    "link": "#/vicariouspt2",
    "description": "Traumatizing events can be directly experienced or witnessed; learned about from others.",
    "img": ppnTherapist4,
    "topic": "Vicarious Trauma",
    "alpha": "V",
    "author": "Bobbi Mcgraw",
    "date": "July 14, 2023"
  },
  {
    "id": 13,
    "title": "Vicarious Trauma, Part 1",
    "link": "#/vicariouspt1",
    "description": "Recognize the type of trauma you are exposed to.",
    "img": plantFlipped,
    "topic": "Vicarious Trauma",
    "alpha": "V",
    "author": "Bobbi Mcgraw",
    "date": "July 7, 2023"
  },
  {
    "id": 12,
    "title": "Neuroscience",
    "link": "#/neuroscience",
    "description": "3rd part in the neuroscience series, the importance of good sleep.",
    "img": resourceImg,
    "topic": "Neuroscience",
    "alpha": "N",
    "author": "Bobbi Mcgraw",
    "date": "June 30, 2023"
  },
  {
    "id": 11,
    "title": "Happiness",
    "link": "#/happiness",
    "description": "2nd part in the neuroscience series (taken from Harvard Health and Mayo Clinic).",
    "img": ppnTherapist3small,
    "topic": "Neuroscience happiness",
    "alpha": "N",
    "author": "Bobbi Mcgraw",
    "date": "June 16, 2023"
  },
  {
    "id": 10,
    "title": "Social Media",
    "link": "#/socialmedia",
    "description": "The brain and social media. 1st in a three-part series of neuroscience.",
    "img": ppnTherapist2small,
    "topic": "Neuroscience social media",
    "alpha": "N",
    "author": "Bobbi Mcgraw",
    "date": "June 9, 2023"
  },
  {
    "id": 9,
    "title": "Connection",
    "link": "#/connection",
    "description": "Let this small message give you hope and shed light on your gifts and help you identify YOUR value. Let it help you stay connected.",
    "img": ppnTherapist1small,
    "topic": "Connection",
    "alpha": "C",
    "author": "Bobbi Mcgraw",
    "date": "June 2, 2023"
  },
  {
    "id": 8,
    "title": "Mental Health Awareness",
    "link": "#/opentomhawareness",
    "description": "Intentional conversations help remove the stigma.",
    "img": ppnTherapist5,
    "topic": "Openness mental health awareness",
    "alpha": "O",
    "author": "Bobbi Mcgraw",
    "date": "May 26, 2023"
  },
  {
    "id": 7,
    "title": "Resilience",
    "link": "#/resilience",
    "description": "Resilience is our inner strength to rebound from adversity.",
    "img": faithImage3Small,
    "topic": "Hope",
    "alpha": "H",
    "author": "Bobbi Mcgraw",
    "date": "May 19, 2023"
  },
  {
    "id": 6,
    "title": "Hope",
    "link": "#/Hope",
    "description": "A genuine sense of faith that life, truly is good. It's knowing things will get better.",
    "img": handsDarkened,
    "topic": "Hope",
    "alpha": "H",
    "author": "Bobbi Mcgraw",
    "date": "May 12, 2023"
  },
  {
    "id": 5,
    "title": "Trauma",
    "link": "#/trauma",
    "description": "Decades of research have shown trauma to be one of the leading causes of mental health concerns.",
    "img": specialtyGroups,
    "topic": "Trauma",
    "alpha": "T",
    "author": "Bobbi Mcgraw",
    "date": "May 5, 2023"
  },
  {
    "id": 4,
    "title": "Stigma",
    "link": "#/stigma",
    "description": "Stigma is stereotyping a person based on inaccurate information and/or beliefs.",
    "img": handsin,
    "topic": "Stigma",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "April 28, 2023"
  },
  {
    "id": 3,
    "title": "Sleep",
    "link": "#/sleep",
    "description": "It is vital to every living creature, you included.",
    "img": sleep2,
    "topic": "Sleep",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "April 21, 2023"
  },
  {
    "id": 2,
    "title": "Transference",
    "link": "#/transference",
    "description": "A return of old feelings you had in a specific moment.",
    "img": transference,
    "topic": "Transference",
    "alpha": "T",
    "author": "Bobbi Mcgraw",
    "date": "April 14, 2023"
  },
  {
    "id": 1,
    "title": "Microbiome",
    "link": "#/microbiome",
    "description": "Microbiome, you say? What's it got to do with sleep? Actually, what is it?",
    "img": microbiome,
    "topic": "Physical Health Microbiome",
    "alpha": "M",
    "author": "Bobbi Mcgraw",
    "date": "April 7, 2023"
  },
  {
    "id": 0,
    "title": "Sleep Hygiene",
    "link": "#/sleephygiene",
    "description": "Tired? Exhausted? Can't Sleep? What do you know about sleep hygiene?",
    "img": sleep,
    "topic": "Sleep",
    "alpha": "S",
    "author": "Bobbi Mcgraw",
    "date": "March 31, 2023"
  }
]
