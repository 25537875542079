import routes from './routes';
import '../src/CSS/App.css';
import '../src/CSS/index.css';



function App() {

  return (
    <div className="App">
   {routes}
    </div>
  );
}

export default App;
