import React from "react";
import './ppn.css';

const ProviderBenefits = () => {
  return (
    <div className="provben-container">
      <h1>Provider Benefits</h1>
      <ul className="benefits-list">
        <li>Evidence-based assessment results sent to you with ROI approval from client upon acceptance of services</li>
        <li>Individuals accepting services become your clients upon referral from Previdence</li>
        <li>Access to Previdence First Responder Culture Training and Previdence Assessment Training </li>
        <li>Ongoing support for therapists from the Previdence Therapist Advocate Team</li>
      </ul>
    </div>
  );
};
export default ProviderBenefits;